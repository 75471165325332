import React from "react"
import Layout from "@components/layout"
import Container from "@components/layout/container"
import Div from "@components/elements/div"
import Paragraph from "@components/elements/paragraph"
import Anchor from "@components/elements/anchor"
import Heading from "@components/elements/heading"
import GradientText from "@components/common/gradient-text"

const GonePage = () => (
  <Layout
    fullWidth
    title={`410: Gone`}
    description={`The page you’re looking for is gone.`}
    marginless
  >
    <Container text>
      <Div margin="10rem auto 0" center>
        <Heading
          fontSize="14rem"
          mobileScale="0.5"
          color="white"
          style={{ minWidth: "max-content" }}
        >
          <GradientText
            gradient={"linear-gradient(90deg, #7B6CCC 0%, #886BC1 100%)"}
            style={{
              filter: "blur(4px)",
              transform: "rotate(-15deg)",
              marginRight: "1rem",
            }}
          >
            G
          </GradientText>
          <GradientText
            gradient={"linear-gradient(90deg, #956AB6 0%, #A26AAA 100%)"}
            style={{
              marginLeft: "-0.5rem",
              filter: "blur(8px)",
              transform: "rotate(-5deg) scale(1.1)",
              marginRight: "1rem",
            }}
          >
            O
          </GradientText>
          <GradientText
            gradient={"linear-gradient(90deg, #AF699F 0%, #BC6894 100%)"}
            style={{
              marginLeft: "-1rem",
              filter: "blur(16px)",
              transform: "rotate(5deg)",
            }}
          >
            N
          </GradientText>
          <GradientText
            gradient={"linear-gradient(90deg, #AF699F 0%, #BC6894 100%)"}
            style={{
              filter: "blur(32px)",
              transform: "rotate(20deg)",
            }}
          >
            E
          </GradientText>
        </Heading>
      </Div>
      <Paragraph center margin="1rem auto 6rem">
        This page no longer exists, but you can always{" "}
        <Anchor link="/">go home</Anchor>
      </Paragraph>
    </Container>
  </Layout>
)

export default GonePage
